import React from "react";
import {Badge, Col, Container, ListGroupItem, Row} from "shards-react";

const LegendItem = (props) => {
    const {name, description, valueTrue, valueFalse, id} = props;

    return <ListGroupItem key={id} theme="dark" style={{backgroundColor: "inherit", color: "white"}}>
        <Container>
            <Row>
                <Col sm="12" lg="3" md="3" xl="3">{name}</Col>
                <Col sm="12" lg="3" md="3" xl="3">{description}</Col>
                <Col sm="12" lg="3" md="3" xl="3">
                    <Badge outline pill theme="success">
                        <span style={{width: '5rem', display: 'block'}}>{valueTrue}</span>
                    </Badge>
                </Col>
                <Col sm="12" lg="3" md="3" xl="3">
                    <Badge outline pill theme="danger">
                        <span style={{width: '10rem', display: 'block'}}>{valueFalse}</span>
                    </Badge>
                </Col>
            </Row>
        </Container>
    </ListGroupItem>
}

export default LegendItem;