import React from "react";
import ReactHtmlParser from 'react-html-parser';

import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Form,
    Popover,
    PopoverBody,
    PopoverHeader
} from "shards-react";

import "./pod-control.styles.scss";
import ToggleButton from "../button/button.component";
import CircleButton from "../circle-button/circle-button.component";
import {useDispatch, useSelector} from "react-redux";
import {addHistoryItem} from "../../redux/history/history.actions";

function onClickHandler({ notifyCallback }, id, toggleOn, dispatch) {
    notifyCallback(id, toggleOn);
    dispatch(addHistoryItem({id: id, toggleOn: toggleOn, date: new Date().toUTCString()}));
}

function onClickToggle(dispatch, action) {
    return () => dispatch(action());
}

const PodControlCard = (props) => {
    const { controls, title, titleOn, titleOff, id, action } = props;
    const selector = props.selector ?? (() => false);
    const dataSelector = props.data ?? (() => null);
    const dispatch = useDispatch();
    const isOpen = useSelector(selector);
    const data = useSelector(dataSelector);

    const toggleFunction = onClickToggle(dispatch, action);

    let targetId = `#popover-${id}`;

    return (
        <Card style={{ maxWidth: "100%", backgroundColor: "inherit" }} className="main-card">
            <CardHeader>
                <span style={{float: "left"}}>{title}</span>
                <CircleButton style={{float: "right"}} id={`popover-${id}`} onClick={toggleFunction}/>
            </CardHeader>
            <CardBody>
                <Popover
                    id={`popover-${id}-container`}
                    placement="bottom"
                    open={isOpen}
                    toggle={toggleFunction}
                    target={targetId}
                >
                    <PopoverHeader style={{color: "white", backgroundColor: "#343a40"}}>Information</PopoverHeader>
                    <PopoverBody style={{color:"white", backgroundColor: "#212529"}}>
                        {ReactHtmlParser(data)}
                    </PopoverBody>
                </Popover>
                <Form>
                    <label htmlFor="turnOnButtons" className="mr-2">{titleOn}</label>
                    <br />
                    <ButtonGroup id="turnOnButtons" className="mr-2 btn-group-responsive" >
                        {controls.map((control, index) => {
                            return <ToggleButton
                                onClick={() => onClickHandler(props, control.id, true, dispatch)}
                                key={index}
                                toggleOn={true}
                                id={control.id}
                                entrypoint="py_com_control">
                                {control.label}
                            </ToggleButton> })
                        }
                    </ButtonGroup>
                </Form>
                <br />
                <Form>
                    <label htmlFor="turnOffButtons" className="mr-2">{titleOff}</label>
                    <br />
                    <ButtonGroup id="turnOffButtons" className="mr-2 btn-group-responsive">
                        {controls.map((control, index) => {
                            return <ToggleButton
                                onClick={() => onClickHandler(props, control.id, false, dispatch)}
                                key={index}
                                id={control.id}
                                entrypoint="py_com_control">
                                {control.label}
                            </ToggleButton> })
                        }
                    </ButtonGroup>
                </Form>
            </CardBody>
        </Card>
    );
}

export default PodControlCard