import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import { historyReducer } from './history/history.reducer';
import { persistHistoryConfig } from './history/history.constants';
import {popupReducer} from "./popups/popups.reducer";
import {legendReducer} from "./legend/legend.reducer";

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['history'],
};

const rootReducer = combineReducers({
  history: persistReducer(persistHistoryConfig, historyReducer),
  popups: popupReducer,
  legend: legendReducer
});

export default persistReducer(persistConfig, rootReducer);
