import React, {useEffect, useState} from "react";

import './style.css'

import {
    Badge,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"

import controls from "./data/features.controls.json"
import valves from "./data/valves.controls.json"

import PodControlCard from "./components/pod-control-card/pod-control-card.component";
import ToggleButton from "./components/button/button.component";
import {selectHistoryItems} from "./redux/history/history.selectors";
import {useSelector} from "react-redux";
import {Dna} from "react-loader-spinner";
import {toggleAutomationPopup, toggleValvePopup} from "./redux/popups/popups.actions";
import {
    selectAutomationPopupData,
    selectAutomationPopupStatus,
    selectValvePopupData,
    selectValvePopupStatus
} from "./redux/popups/popups.selectors";
import {selectLegendItems} from "./redux/legend/legend.selectors";
import Legend from "./components/legend/legend.component";

const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function App() {
    const [isLoaded, setIsLoaded] = useState(false)
    const historyItems = useSelector(selectHistoryItems);
    const items = useSelector(selectLegendItems);

    const notify = (id, toggleOn) => toggleOn === true
        ? toast.success(`Sent command: ${id}_${toggleOn ? "on" : "off"}`, toastConfig)
        : toast.error(`Sent command: ${id}_${toggleOn ? "on" : "off"}`, toastConfig);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 600)
    }, []);

    if (!isLoaded) {
        return <Container style={{
            minHeight: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Row>
                <Col>
                    <Dna
                        visible={true}
                        height="240"
                        width="240"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </Col>
            </Row>
        </Container>
    }

    return (
        <Container>
            <Container>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </Container>
            <Row>
                <Col>
                    <PodControlCard
                        notifyCallback={notify}
                        id="automation-control"
                        action={toggleAutomationPopup}
                        selector={selectAutomationPopupStatus}
                        data={selectAutomationPopupData}
                        title="POD Automation Control"
                        titleOn="Start"
                        titleOff="Stop"
                        controls={controls}
                    />
                </Col>
                <Col>
                    <PodControlCard
                        notifyCallback={notify}
                        id="valve-control"
                        action={toggleValvePopup}
                        selector={selectValvePopupStatus}
                        data={selectValvePopupData}
                        title="POD Valve Control"
                        titleOn="Open"
                        titleOff="Close"
                        controls={valves}
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{maxWidth: "100%", backgroundColor: "inherit"}}>
                        <CardHeader>POD Automation</CardHeader>
                        <CardBody>
                            <ButtonGroup id="turnOnButtons" className="mr-2 btn-group-responsive">
                                <ToggleButton
                                    id="start"
                                    onClick={() => notify("start", true)}
                                    entrypoint="py_com_automation"
                                    toggleOn={true}>
                                    Start POD Automation
                                </ToggleButton>
                                <ToggleButton
                                    id="stop"
                                    onClick={() => notify("stop", false)}
                                    entrypoint="py_com_automation"
                                    toggleOn={false}>
                                    Stop POD Automation
                                </ToggleButton>
                            </ButtonGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Legend data={items} />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{maxWidth: "100%", backgroundColor: "inherit"}}>
                        <CardHeader>POD Commands History</CardHeader>
                        <CardBody>
                            <ListGroup>
                                {
                                    historyItems.map((item, index) => (
                                        <ListGroupItem id={index} theme={item.toggleOn ? "success" : "danger"}
                                                       style={{border: "1px solid black"}}><Badge
                                            theme="secondary">{item.date}</Badge> {item.id} - {item.toggleOn ? "On" : "Off"} command</ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default App;
