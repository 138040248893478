import { HISTORY_ACTION_TYPES, HISTORY_INITIAL_STATE } from './history.constants';
import { addItemToHistory } from './history.utils';

export const historyReducer = (state = HISTORY_INITIAL_STATE, action) => {
    switch (action.type) {
        case HISTORY_ACTION_TYPES.ADD_HISTORY_ITEM:
            return {
                ...state,
                items: addItemToHistory(state.items, action.payload)
            };
        case HISTORY_ACTION_TYPES.CLEAR_HISTORY:
            return {
                ...state,
                items: []
            };
        default:
            return {...state};
    }
};
