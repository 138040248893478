import React from 'react';

import {Button} from "shards-react";
import send from "../../data/com_api/send";

const clickHandler = async (event, toggleOn, entrypoint, onClickHandler) => {
    event.preventDefault();

    onClickHandler();

    const suffix = toggleOn ? 'on' : 'off';
    const response = await send(`${entrypoint}.py?id=/${event.target.id}_${suffix}`, null);

    console.log(response);
}

const ToggleButton = (props) => {
    const btnStyle = props.toggleOn ? 'success' : 'danger';

    return <Button
        theme={btnStyle}
        style={{minHeight: "78px", border: "1px solid white"}}
        id={props.id}
        onClick={(event) => clickHandler(event, props.toggleOn, props.entrypoint, props.onClick)}
    >
        {props.children}
    </Button>;
}

export default ToggleButton;