import { HISTORY_ACTION_TYPES as ACTION_TYPES } from './history.constants';

export const addHistoryItem = (item) => ({
    type: ACTION_TYPES.ADD_HISTORY_ITEM,
    payload: item
});

export const removeHistoryItem = (item) => ({
    type: ACTION_TYPES.CLEAR_HISTORY_ITEM,
    payload: item
});

export const clearHistory = () => ({
    type: ACTION_TYPES.CLEAR_HISTORY
});
