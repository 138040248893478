import axios from "axios";
import qs from "qs";

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

const send = async (url, data) => {
    return await axios.post(url, qs.stringify(data), config);
}


export default send;