import {POPUP_ACTION_TYPES as ACTION_TYPES} from "./popups.constants";

export const toggleValvePopup = () => {
    return {
        type: ACTION_TYPES.TOGGLE_VALVE,
        payload: null
    }
};

export const toggleAutomationPopup = () => {
    return {
        type: ACTION_TYPES.TOGGLE_AUTOMATION,
        payload: null
    }
};

