export const LEGEND_INITIAL_STATE = {
    items: [
        {
            name: "Tote Overfill sensor",
            description: "D11",
            valueTrue: "1 = normal",
            valueFalse: "0 = triggered or NC"

        },
        {
            name: "Tote Fill sensor",
            description: "D12",
            valueTrue: "1 = normal",
            valueFalse: "0 = overfill or NC"
        },
        {
            name: "Tote Overfill sensor",
            description: "D11",
            valueTrue: "1 = normal",
            valueFalse: "0 = triggered or NC"
        },
        {
            name: "rPipe",
            description: "D13",
            valueTrue: "1 = normal",
            valueFalse: "0 = overflow or NC"
        },
        {
            name: "Drain Valve",
            description: "D14",
            valueTrue: "1 = normal",
            valueFalse: "0 = open or not connected"
        },
        {
            name: "Transfer Valve",
            description: "D15",
            valueTrue: "1 = normal",
            valueFalse: "0 = open or not connected"
        },
        {
            name: "Dump Valve",
            description: "D16",
            valueTrue: "1 = normal",
            valueFalse: "0 = open or not connected"
        }
    ]
};