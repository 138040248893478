import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import sessionStorage from 'redux-persist/lib/storage/session';

export const POPUP_PUMP_DATA = `
    <p>Pump - Pump On (green) / Off (red), default is Off</p>
    <p>Hydrogen - Hydrogen On (green) / Off (red), default is Off</p>
    <p>Lights - Lights On (green) / Off (red), default is Off</p>
    <p>Heater - Heater On (green) / Off (red), default is Off</p>
    <p>Tub - Tub lights On (green) / Off (red), default is Off</p>
    <p>LED - LED lights On (green) / Off (red), default is Off</p>
`

export const POPUP_VALVE_DATA = `
    <p>Drain - Drain valve Open (green) / Close (red), closed by default</p>
    <p>Transfer - Transfer valve Open (green) / Close (red), closed by default</p>
    <p>Dump - Dump valve Open (green) / Close (red), closed by default</p>
    <p>Fill - Fill valve Open (green) / Close (red), closed by default</p>
`;

export const POPUP_INITIAL_STATE = {
    valveOpen: false,
    automationOpen: false,
    valveData: POPUP_VALVE_DATA,
    automationData: POPUP_PUMP_DATA
};

export const POPUP_TYPES = {
    VALVE: "valveOpen",
    AUTOMATION: "automationOpen"
};

export const POPUP_ACTION_TYPES = {
    TOGGLE_VALVE: 'TOGGLE_VALVE_POPUP',
    TOGGLE_AUTOMATION: 'TOGGLE_AUTOMATION_POPUP',
    TOGGLE: 'TOGGLE_POPUP',
    GET_VALVE_DATA: 'GET_VALVE_DATA',
    GET_AUTOMATION_DATA: 'GET_AUTOMATION_DATA',
};