import {POPUP_ACTION_TYPES, POPUP_INITIAL_STATE, POPUP_TYPES} from "./popups.constants";

export const popupReducer = (state = POPUP_INITIAL_STATE, action) => {
    switch (action.type) {
        case POPUP_ACTION_TYPES.TOGGLE_VALVE:
            return {
                ...state,
                [POPUP_TYPES.VALVE]: !state[POPUP_TYPES.VALVE]
            };
        case POPUP_ACTION_TYPES.TOGGLE_AUTOMATION:
            return {
                ...state,
                [POPUP_TYPES.AUTOMATION]: !state[POPUP_TYPES.AUTOMATION]
            };
        default:
            return {...state};
    }
};
