import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import sessionStorage from 'redux-persist/lib/storage/session';

export const HISTORY_INITIAL_STATE = {
    items: [],
};

export const HISTORY_ACTION_TYPES = {
    ADD_HISTORY_ITEM: 'ADD_HISTORY_ITEM',
    CLEAR_HISTORY_ITEM: 'CLEAR_HISTORY_ITEM',
    CLEAR_HISTORY: 'CLEAR_HISTORY'
};

export const persistHistoryConfig = {
    key: 'history',
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
}
