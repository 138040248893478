import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import PersistProvider from "./providers/persist.provider";
import ReduxProvider from "./providers/redux.provider";
import {ProSidebarProvider} from "react-pro-sidebar";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ReduxProvider>
            <PersistProvider>
                <ProSidebarProvider>
                    <App/>
                </ProSidebarProvider>
            </PersistProvider>
        </ReduxProvider>
    </React.StrictMode>
);
