import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../redux/store';
import {Dna} from "react-loader-spinner";

const PersistProvider = ({ children }) => {
  return <PersistGate
      loading={
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      }
      persistor={persistor}>
    {children}
  </PersistGate>;
};

export default PersistProvider;
