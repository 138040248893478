import { createSelector } from 'reselect';

const selectPopupsState = (state) => state.popups;

export const selectValvePopupStatus = createSelector([selectPopupsState], (popup) => popup.valveOpen);
export const selectAutomationPopupStatus = createSelector([selectPopupsState], (popup) => popup.automationOpen);

export const selectAutomationPopupData = createSelector([selectPopupsState], (state) => state.automationData);

export const selectValvePopupData = createSelector([selectPopupsState], (state) => state.valveData);

