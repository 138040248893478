import React from 'react';
import {Card, CardBody, CardHeader, ListGroup } from "shards-react";

import LegendItem from "../legend-item/legend-item.component";

const Legend = (props) => {
    const {data} = props;

    return <Card style={{maxWidth: "100%", backgroundColor: "inherit"}}>
        <CardHeader>POD Legend Description</CardHeader>
        <CardBody>
            <ListGroup theme="dark">
                {
                    data.map((item, index) => <LegendItem key={index} {...item} />)
                }
            </ListGroup>
        </CardBody>
    </Card>
}

export default Legend;